<template>
  <v-container fluid>
    <v-row justify="center" class="mt-3" ref="top">
      <v-col cols="12 pl-6">
        <h2 class="my-0 primary--text">
          Inclusão de grupo familiar
        </h2>
      </v-col>
      <v-col cols="12">
        <PrincipalInformations
          class="pt-0"
          :isDetail="false"
          :showHeaderPage="true"
          :showAdmissionDate="true"
          :showRegistrationPlate="false"
          :showCardNumber="false"
          @observerHolderInformationData="observerHolderInformationData"
        />
      </v-col>
      <v-col cols="12">
        <PlanInformation
          class="py-0"
          :movementNotAllowed="!isEdit && !isCriticizedCarrier ? false : true"
          :contractedPlanData="contractedPlanData"
          :isDetail="false"
          :showHeaderPage="true"
          :showEligibility="true"
          :showStartValidityDate="true"
          :showEndValidityDate="false"
          :showCancellationReason="false"
          :showFamilyCode="false"
          :showFamilyCodeSequential="false"
          :showGrace="!isRHProtegido"
          :isRHProtegido="isRHProtegido"
          @observerContractedPlan="observerContractedPlan"
          @getCombinedDataPlans="getCombinedDataPlans"
        />
      </v-col>
      <v-col cols="12">
        <ComplementaryInformations
          class="pt-0"
          :propsGenders="genders"
          :propsMaritalStatus="maritalStatus"
          :holderComplementaryData="holderComplementaryData"
          :showHeaderPage="true"
          :isDetail="false"
          :isFreeMovement="isFreeMovement"
          @observerHolderComplementaryData="observerHolderComplementaryData"
          :showRegistrationPlate="true"
        />
      </v-col>
      <v-col cols="12 pt-0">
        <AddressInformations
          class="ml-3"
          :addressData="addressData"
          :isFreeMovement="isFreeMovement"
          :showHeaderPage="true"
          :isDetail="false"
          @observerAddressData="observerAddressData"
        />
      </v-col>
      <v-col cols="12 pt-0">
        <BankInformations
          class="pt-0"
          :showHeaderPage="true"
          :isFreeMovement="false"
          :isDetail="false"
          @observerBankData="observerBankData"
        />
      </v-col>
      <v-col cols="12 pt-0">
        <UploadDocuments
          ref="UploadDocuments"
          class="pt-0 mx-3 ml-6"
          :showEditAndSaveButton="false"
          :movementRecordId="movementRecord.id"
          :documentParams="documentParams"
          :movementType="
                        $route.query && $route.query.movementType
                        ? $route.query.movementType.toUpperCase()
                        : ''"
          :beneficiaryType="$route.query && $route.query.beneficiaryType ? $route.query.beneficiaryType.toUpperCase(): ''"
          @observerDocumentData="observerDocumentData"
          :startSearch="startSearch"
          @searchEnded="finishSearch"
        />
      </v-col>
    </v-row>

    <v-col cols="12 pt-0">
      <DependentsExpansion
        ref="DependentsExpansion"
        class="pt-0"
        :isDetail="false"
        :propsGenders="genders"
        :propsMaritalStatus="maritalStatus"
        :propsKinships="kinships"
        :contractedPlanData="dataPlans"
        :holder="holder"
        :expandedAll="expandedAll"
        @expanded="expandedAll = false"
        @observerDependents="observerDependents"
      />
    </v-col>

    <div class="px-3" v-if="violations.length > 0">
      <ViolationRulesAlert :violations="violations" />
    </div>

    <v-footer class="my-5">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            outlined
            color="textPrimary"
            @click="onClickCancel()"
          >
            Cancelar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            @click="onClickFinish()"
          >
            Concluir
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-dialog v-model="validateGraceModal" persistent max-width="800">
      <v-card width="800" class="pa-3 rounded-lg">
        <v-card-title class="text-h5 font-weight-bold" style="color: #3b495b;">
          <v-row
            class="d-flex justify-space-between px-3 pt-3">
            <span>Incidência de Carência</span>
            <v-icon @click="closeValidateGraceModal()">
              fas fa-times
            </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form ref="formFilterExportMovement">
            <v-row justify="start">
              <v-col>
                <p>A movimentação desejada excede o prazo máximo dias com relação à Data do Evento. Portanto, incidirá carência.</p>
                <div v-for="grace in gracePeriods">
                  <p v-if="grace.hasGracePeriod">
                    {{grace.benefitId}} - {{grace.policy}} - Acima do prazo máximo de {{grace.daysGracePeriod}} dias
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions
          class="px-6 pb-3">
          <v-spacer />
          <v-btn color="primary" width="150px" outlined @click="closeValidateGraceModal()">
            Cancelar
          </v-btn>
          <v-btn color="primary" width="150px" outlined @click="nonConfirmGracePeriod()">
            Atendimento
          </v-btn>
          <v-btn color="primary" width="150px" @click="confirmGracePeriod()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="isCriticizedBroker" style="position: absolute; z-index: 1;">
      <ViewCriticizedViolationDialog />
    </div>

    <ReleaseMovementModal
      ref="ReleaseMovementModal"
      @close="closeConfirmReturnModal()"
      @confirm="updateStatus()"
    />

    <InfoMovementDialog
      :show="showReleaseInfo" :movementType="movementType"
      :messageType="releaseMessageType"
      @close="closeConfirmReturnModal()"
      :beneficiaryName="holder && holder.name ? formatter.formatToTitleCase(holder.name) : null"
    />

    <FullScreenLoader
      v-if="loaded"
    />

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>

    <SnackbarCustomize ref="SnackbarCustomize"/>

    <CriticismHandlingModal ref="CriticismHandlingModal" @confirm="onClickFinish" />
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rule from '@/shared/validators/formRules';
import GenderService from '@/services-http/contract/GenderService';
import ContractService from '@/services-http/contract/ContractService';
import MaritalStatusService from '@/services-http/contract/MaritalStatusService';
import KinshipService from '@/services-http/contract/KinshipService';
import PrincipalInformations from '@/components/Beneficiary/Holder/PrincipalInformations.vue';
import PlanInformation from '@/components/Beneficiary/Contracted/PlanInformation.vue';
import ComplementaryInformations from '@/components/Beneficiary/Holder/ComplementaryInformations.vue';
import AddressInformations from '@/components/Beneficiary/Holder/AddressInformations.vue';
import BankInformations from '@/components/Beneficiary/Holder/BankInformations.vue';
import UploadDocuments from '@/components/Beneficiary/Documents/UploadDocuments.vue';
import DependentsExpansion from '@/components/Beneficiary/Dependent/DependentsExpansion.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import ViewCriticizedViolationDialog from '@/components/DynamicDialogInformation/ViewCriticizedViolationDialog.vue';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import UserUtils from '@/shared/utils/user-utils';
import InfoMovementDialog from '@/components/MovementDialog/InfoMovementDialog.vue';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import MovementRecordFormFieldsToValidate from '@/shared/validators/movementRecordFormFieldsToValidate';
import ReleaseMovementModal from '@/components/ReleaseMovement/ReleaseMovementModal.vue';
import CriticismHandlingModal from '@/components/CustomAlerts/CriticismHandlingModal.vue';
import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';

export default {
  name: 'FamilyGroupInclusionUpdate',
  components: {
    PrincipalInformations,
    PlanInformation,
    ComplementaryInformations,
    AddressInformations,
    BankInformations,
    UploadDocuments,
    DependentsExpansion,
    InfoMovementDialog,
    ViolationRulesAlert,
    ViewCriticizedViolationDialog,
    SnackbarCustomize,
    ReleaseMovementModal,
    CriticismHandlingModal,
    FullScreenLoader,
  },
  data: () => ({
    holder: {
      name: null,
      document: null,
      admissionDate: null,
    },
    contractedPlanData: {
      financialGroupId: null,
      contractId: null,
      subContractId: null,
      eligibilityId: null,
      planId: null,
    },
    holderComplementaryData: {},
    addressData: {},
    bankData: {},
    movementRecord: {},
    payload: {},
    dataPlans: {},

    hasAuthorityReleaseMovement: false,
    waitingDocumentUpload: false,
    isCriticizedCarrier: false,
    isCriticizedBroker: false,
    isEdit: false,
    overlay: false,
    isFreeMovement: false,
    isRHProtegido: false,
    showReleaseInfo: false,

    waitingRulesApprovalIds: [],
    genders: [],
    maritalStatus: [],
    kinships: [],
    violations: [],
    dependents: [],

    movementType: 'Inclusão de Grupo Familiar',
    successReleaseMessageType: 'RELEASE_SUCCESS',
    errorReleaseMessageType: 'RELEASE_ERROR',
    releaseMessageType: '',
    persistWithCriticism: false,
    validateGraceModal: false,
    gracePeriods: [],
    documentParams: {
      contractId: null,
      carrierId: null,
      movementType: 'INSERT',
      hasGracePeriod: false,
      beneficiaryType: null,
      benefitTypeCode: null,
    },
    startSearch: false,
    expandedAll: false,
    loaded: false,
  }),

  mixins: [
    VerifyRoutesMixin,
    AuthorityManagementMixin,
  ],

  async beforeMount() {
    await this.mapBeneficiaryMovementRecord();
  },

  mounted() {
    this.scrollTo();
    this.verifyFreeMovement();
    this.verifyRouteRHProtegidoMixin();
    this.verifyTypeOperationForMovement();
    this.loadAuthorityMovement();
    this.loadGenders();
    this.loadMaritalStatus();
    this.loadKinships();

    setTimeout(() => {
      this.startSearch = true;
      this.loaded = false;
    }, 2000)
  },

  methods: {
    verifyFreeMovement() {
      if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
        this.isFreeMovement = true;
      }
    },
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async loadAuthorityMovement() {
      this.hasAuthorityReleaseMovement = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_painel_movimentacoes_cadastrais_liberar_movimentacao' });
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.genders = response.data;
          this.genders.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    async loadMaritalStatus() {
      await this.maritalStatusService.FindAll().then((response) => {
        if (response && response.data) {
          this.maritalStatus = response.data;
          this.maritalStatus.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    async loadKinships() {
      await this.kinshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.kinships = response.data.filter(kinship =>
                              kinship.id !== 22
                              || kinship.code !== 'Titular'
                              || kinship.description !== 'Titular');
          this.kinships.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    async mapBeneficiaryMovementRecord() {
      if (sessionStorage.getItem('movementRecord')) {
        this.movementRecord = await JSON.parse(sessionStorage.getItem('movementRecord'));
        this.holderName = this.movementRecord.holderName ? this.formatter.formatToTitleCase(this.movementRecord.holderName) : null;

        this.holder = {
          name: this.movementRecord.holderName,
          document: this.movementRecord.holderDocumentNumber ? this.formatter.formatDate(this.movementRecord.holderDocumentNumber) : null,
          admissionDate: this.movementRecord.eventDate,
          department: this.movementRecord.costCenterDepartment ? this.movementRecord.costCenterDepartment : null,
          capacityCode: this.movementRecord.taxAllocationCode ? this.movementRecord.taxAllocationCode : null,
          capacityDescription: this.movementRecord.taxAllocationDescription ? this.movementRecord.taxAllocationDescription : null,
        };

        this.contractedPlanData.financialGroupId = this.movementRecord.financialGroupId ? this.movementRecord.financialGroupId : null;
        this.contractedPlanData.contractId = this.movementRecord.contractId ? this.movementRecord.contractId : null;
        this.contractedPlanData.subContractId = this.movementRecord.subContractId ? Number(this.movementRecord.subContractId) : null;
        this.contractedPlanData.eligibilityId = this.movementRecord.contractEligibilityId ? Number(this.movementRecord.contractEligibilityId) : null;
        this.contractedPlanData.planId = this.movementRecord.plan ? Number(this.movementRecord.plan) : null;
        this.contractedPlanData.startValidityDate = this.movementRecord.startDate;
        this.contractedPlanData.hasGracePeriod = this.movementRecord.hasGracePeriod;
        this.addressData = this.movementRecord;

        this.documentParams.carrierId = this.movementRecord.insuranceCarrierId;
        this.documentParams.contractId = this.contractedPlanData.contractId;
        this.documentParams.hasGracePeriod = this.movementRecord.hasGracePeriod ? true : false;
        this.documentParams.movementType = this.movementRecord.movementType;
        this.documentParams.beneficiaryType = this.movementRecord.beneficiaryType;


        this.holderComplementaryData.cellphone = this.movementRecord.cellphoneNumber && this.movementRecord.cellphoneDDD ? this.movementRecord.cellphoneDDD + this.movementRecord.cellphoneNumber : null;
        this.holderComplementaryData.telephone = this.movementRecord.homephoneDDD && this.movementRecord.homephoneNumber ? this.movementRecord.homephoneDDD + this.movementRecord.homephoneNumber : null;
        this.holderComplementaryData.companyDocumentNumber = this.movementRecord.companyDocumentNumber;
        this.holderComplementaryData.motherName = this.movementRecord.motherName;
        this.holderComplementaryData.cns = this.movementRecord.cns;
        this.holderComplementaryData.dnv = this.movementRecord.dnv;
        this.holderComplementaryData.companyName = this.movementRecord.companyName;
        this.holderComplementaryData.pisPasep = this.movementRecord.pisPasep;
        this.holderComplementaryData.registrationPlate = this.movementRecord.registrationPlate;
        this.holderComplementaryData.registration = this.movementRecord.registrationPlate;
        this.holderComplementaryData.nationality = this.movementRecord.nationality;
        this.holderComplementaryData.stateBirth = this.movementRecord.stateBirth;
        this.holderComplementaryData.weight = this.movementRecord.weight;
        this.holderComplementaryData.height = this.movementRecord.height;
        this.holderComplementaryData.rne = this.movementRecord.rne;
        this.holderComplementaryData.email = this.movementRecord.email;
        this.holderComplementaryData.identityDocumentNature = this.movementRecord.natureIdentifyDocument;
        this.holderComplementaryData.identityDocumentNumber = this.movementRecord.rgDocumentIdentify;
        this.holderComplementaryData.identityDocumentEmissionDate = this.movementRecord.rgDocumentIssueDate;
        this.holderComplementaryData.identityDocumentIssuer = this.movementRecord.rgDocumentIssuingBody;
        this.holderComplementaryData.identityDocumentState = this.movementRecord.documentStateIssuer;
        this.holderComplementaryData.identityDocumentCountry = this.movementRecord.documentCountryIssue;
        this.holderComplementaryData.birthDate = this.movementRecord.birthDate;
        this.holderComplementaryData.birthday = this.movementRecord.birthday;
        this.holderComplementaryData.maritalStatus = this.movementRecord.maritalStatus;
        this.holderComplementaryData.gender = this.movementRecord.gender;
        this.holderComplementaryData.job = this.movementRecord.insuredPosition;
        this.holderComplementaryData.employmentRelationship = this.movementRecord.employmentRelationshipId;
        this.holderComplementaryData.workCard = this.movementRecord.professionalCardNumber;
        this.holderComplementaryData.registrationComplement = this.movementRecord.rne;
        this.holderComplementaryData.rne = this.movementRecord.registrationComplement;

      }
    },
    async updateStatus() {
      const movementRecordService = new MovementRecordService();
      this.$refs.ReleaseMovementModal.close();

      if (this.hasAuthorityReleaseMovement && this.waitingRulesApprovalIds.length > 0) {
        const request = {
          ids: [...this.waitingRulesApprovalIds],
          status: 'VALID',
        };

        await movementRecordService.UpdateStatusFromMultipleMovements(request).then(() => {
          this.releaseMessageType = this.successReleaseMessageType;
          this.showReleaseInfo = true;
          this.$refs.SnackbarCustomize.open('success', 'Movimentação liberada com sucesso');
        }).catch(() => {
          this.releaseMessageType = this.errorReleaseMessageType;
          this.showReleaseInfo = true;
          this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao liberar a movimentação');
        });
      } else {
        this.releaseMessageType = this.errorReleaseMessageType;
        this.showReleaseInfo = true;
        this.$refs.SnackbarCustomize.open('error', 'Você não tem permissão para liberar a movimentação');
      }
    },
    openConfirmReturnModal() {
      this.confirmReturnModal = true;
    },
    closeConfirmReturnModal() {
      this.$refs.ReleaseMovementModal.close();
      this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
      this.confirmReturnModal = false;
    },
    observerHolderInformationData(data) {
      this.holder = data;
    },
    observerContractedPlan(data) {
      this.contractedPlanData = data;

      this.documentParams.contractId = this.contractedPlanData.contractId;
      this.documentParams.hasGracePeriod = this.contractedPlanData.hasGracePeriod ? true : false;

      this.startSearch = true;
    },
    finishSearch() {
      this.startSearch = false
    },
    getCombinedDataPlans(data) {
      this.dataPlans = data;
    },
    observerHolderComplementaryData(data) {
      this.holderComplementaryData = data;
    },
    observerAddressData(data) {
      this.addressData = data;
    },
    observerBankData(data) {
      this.bankData = data;
    },
    observerDocumentData(data) {
      this.documents = data;
    },
    observerDependents(data) {
      this.dependents = data;
    },
    formatZipCode(zipCode) {
      if (zipCode.length === 8) {
        const part1 = zipCode.substring(0, 5);
        const part2 = zipCode.substring(5);
        return `${part1}-${part2}`;
      }
      return zipCode;
    },
    onClickCancel() {
      this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido });
    },
    async onClickFinish(isContinue) {
      let dependentValidation = true;

      Object.keys(this.$refs.DependentsExpansion.$refs).forEach((ref, index) => {
        if (ref.startsWith('DependentExpansionForm-')) {
          const docComponentReference = this.$refs.DependentsExpansion.$refs[ref][0];

          if (!docComponentReference.$refs.UploadDocuments.validate()) {
            this.expandedAll = true;

            dependentValidation = false;
          }
        }

        if (ref.startsWith('DependentForm-')) {
          const docComponentReference = this.$refs.DependentsExpansion.$refs[ref][0];

          if (!docComponentReference.validate()) {
            this.expandedAll = true;

            dependentValidation = false;
          }
        }
      });

      if (!dependentValidation) return;

      if (!this.$refs.UploadDocuments.validate()) return;

      this.gracePeriods = [];
      this.persistWithCriticism = !!isContinue;
      this.validateValues();
      this.formatPayloadEditAndCriticizedBroker();
      if(this.isRHProtegido) {
        let params = [{
            parentContractId: this.payload.plan.contractId,
            eventDate: this.payload.holder.admissionDate,
            startDate: this.payload.plan.validityDate,
        }];

        this.getValidateGracePeriod(params);
      } else {
        this.processMovementRecordService();
      }
    },
    validateValues() {
      const dependents = this.formatPayloadDependents();
      let objects = [
        this.contractedPlanData,
        this.holderComplementaryData,
        this.addressData,
        this.bankData,
        this.holder,
        dependents,
        this.documents,
      ];

      objects = this.formatter.validateEmptyValues(objects);
    },
    formatPayloadEditAndCriticizedBroker() {
      this.payload = {
        plan: this.getContractedPlanData(this.contractedPlanData),
        additionalInfo: this.getAdditionalInfo(this.holderComplementaryData, this.holder),
        address: this.getAddressData(this.addressData, 'payload'),
        bank: this.getBankData(this.bankData),
        holder: this.getHolderData(this.holder),
        dependents: this.formatPayloadDependents(),
        documents: this.documentFormatted(this.documents),
      };
    },
    formatPayloadDependents() {
      const filteredDependents = this.dependents.filter((dependent) => !dependent.isDelete);
      return filteredDependents.map((dependent) => {
        const commonProperties = this.buildCommonDependentProperties(dependent, 'payload');

        return {
          ...commonProperties,
          dependentName: dependent.name,
        };
      });
    },
    formatPayloadDependentsValidateInsert() {
      const filteredDependents = this.dependents.filter((dependent) => !dependent.isDelete);

      return filteredDependents.map((dependent) => {
        const commonProperties = this.buildCommonDependentProperties(dependent, 'validate');

        return {
          ...commonProperties,
          movementRecordParentId: this.movementRecord.id,
          plans: [
            {
              contract: this.contractedPlanData.contractId,
              subcontract: this.contractedPlanData.subContractId,
              eligibility: this.contractedPlanData.eligibilityPertainingPlan,
              plan: this.contractedPlanData.planId,
              validityDate: this.contractedPlanData.startValidityDate
                ? this.formatter.formatDateBRtoString(this.contractedPlanData.startValidityDate)
                : null,
            },
          ],
        };
      });
    },
    buildCommonDependentProperties(dependent, typeRequest) {
      const commonProperties = {
        movementRecordId: dependent.id,
        birthDate: dependent.birthDate ? this.formatter.formatDateBRtoString(dependent.birthDate) : null,
        cns: dependent.cns,
        dnv: dependent.dnv ? this.formatter.unmaskDnv(dependent.dnv) : null,
        motherName: dependent.motherName,
        height: dependent.height,
        weight: dependent.weight,
        stateBirth: dependent.stateBirth,
        documents: this.documentFormatted(dependent.documents),
        registrationComplement: dependent.registrationComplement ? dependent.registrationComplement : null,
        address: dependent.address ? this.getAddressObject(dependent.address) : null,
        telephone: dependent.telephone ? dependent.telephone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null,
        cellphone: dependent.cellphone ? dependent.cellphone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null,
        identityDocumentNature: dependent.identityDocumentNature,
        identityDocumentNumber: dependent.identityDocumentNumber,
        identityDocumentEmissionDate: dependent.identityDocumentEmissionDate ? this.formatter.formatDateBRtoString(dependent.identityDocumentEmissionDate) : null,
        identityDocumentIssuer: dependent.identityDocumentIssuer,
        identityDocumentState: dependent.identityDocumentState,
        identityDocumentCountry: dependent.identityDocumentCountry,
        email: dependent.email,
      };

      if (typeRequest === 'validate') {
        commonProperties.fullName = dependent.name;
        commonProperties.document = dependent.cpf ? this.formatter.removeNonDigit(dependent.cpf) : null;
        commonProperties.maritalStatus = dependent.maritalStatus;
        commonProperties.gender = dependent.gender;
        commonProperties.relationship = dependent.degreeKinship;
      } else {
        commonProperties.dependentName = dependent.name;
        commonProperties.dependentCpf = dependent.cpf ? this.formatter.removeNonDigit(dependent.cpf) : null;
        commonProperties.maritalStatusId = dependent.maritalStatus;
        commonProperties.genderId = dependent.gender;
        commonProperties.degreeKinshipId = dependent.degreeKinship;
      }

      return commonProperties;
    },
    getAddressObject(dependent) {
      const address = {};
      address.zip = dependent.code ? dependent.code.replace('-', '') : null;
      address.log = dependent.log;
      address.city = dependent.city;
      address.state = dependent.region;
      address.type = dependent.logType;
      address.country = dependent.country;
      address.neighborhood = dependent.neighborhood;
      address.number = dependent.number;
      address.complement = dependent.complement;
      address.ibgeCode = dependent.ibgeCode;
      return address;
    },
    documentFormatted(documents) {
      if (documents) {
        return documents.map((element) => ({ id: element.id }));
      }
      return [];
    },
    // async processMovementRecordService() {
    //   this.violations = [];
    //   this.overlay = true;
    //   const movementRecordService = new MovementRecordService();

    //   movementRecordService.ValidateInsert(this.payloadValidateInsert, this.fieldsToValidate.getFieldsToValidate('familyGroup')).then(() => {
    //     movementRecordService.UpdateFamilyGroup(this.movementRecord.id, this.isFreeMovement, this.payload, this.isCriticizedBroker).then((response) => {
    //       const { recordList } = response.data;
    //       const allWithStatusWaitingRulesApproval = recordList.every((movement) => movement.status === 'WAITING_APPROVAL_RULES_VALIDATIONS');
    //       if ((this.hasAuthorityReleaseMovement && allWithStatusWaitingRulesApproval) && this.movementRecord.status !== 'SCHEDULED') {
    //         const releaseMovementData = {
    //           beneficiaryName: this.holder.name,
    //           movementType: this.movementType,
    //         };

    //         this.waitingRulesApprovalIds = recordList.map((movement) => movement.id);
    //         this.$refs.ReleaseMovementModal.open(releaseMovementData);
    //         this.overlay = false;
    //       } else {
    //         this.$refs.SnackbarCustomize.open('success', 'Edição concluída com sucesso');
    //         setTimeout(() => {
    //           this.closeConfirmReturnModal();
    //           this.overlay = false;
    //         }, 1000);
    //       }
    //     }).catch(() => {
    //       this.overlay = false;
    //       this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar editar o cadastro');
    //     });
    //   }).catch((error) => {
    //     this.overlay = false;
    //     if (error.response.status === 422) {
    //       this.violations = [...error.response.data.violations];
    //       this.$refs.CriticismHandlingModal.open();
    //     }
    //   });
    // },
    async processMovementRecordService() {
      this.violations = [];
      this.overlay = true;

      try {
        await this.updateFamilyGroup();
      } catch (error) {
        this.handleValidationErrors(error);
      } finally {
        this.overlay = false;
      }
    },
    async updateFamilyGroup() {
      this.payload = {
        ...this.payload,
        persistWithCriticism: this.persistWithCriticism ? this.persistWithCriticism : false,
      };
      const movementRecordService = new MovementRecordService();

      this.payload = this.formatter.validateEmptyValues(this.payload);
      const response = await movementRecordService.UpdateFamilyGroup(this.movementRecord.id, this.isFreeMovement, this.payload, this.isCriticizedBroker);
      const { recordList } = response.data;

      if (this.shouldOpenReleaseModal(recordList)) {
        this.openReleaseModal(recordList);
      } else {
        this.handleSuccessfulEdit();
      }
    },
    shouldOpenReleaseModal(recordList) {
      const allWithStatusWaitingRulesApproval = recordList.every((movement) => movement.status === 'WAITING_APPROVAL_RULES_VALIDATIONS');
      return this.hasAuthorityReleaseMovement && allWithStatusWaitingRulesApproval && this.movementRecord.status !== 'SCHEDULED';
    },
    openReleaseModal(recordList) {
      const releaseMovementData = {
        beneficiaryName: this.holder.name,
        movementType: this.movementType,
      };

      this.waitingRulesApprovalIds = recordList.map((movement) => movement.id);
      this.$refs.ReleaseMovementModal.open(releaseMovementData);
    },
    handleSuccessfulEdit() {
      this.$refs.SnackbarCustomize.open('success', 'Edição concluída com sucesso');
      setTimeout(() => {
        this.closeConfirmReturnModal();
      }, 1000);
    },
    handleValidationErrors(error) {
      if (error.response.status === 422) {
        this.violations = [...error.response.data.violations];

        if (!this.areAllowedToCriticize()) return;

        this.$refs.CriticismHandlingModal.open();
      } else {
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar editar o cadastro');
      }
    },
    areAllowedToCriticize() {
      if (!this.hasPermission('sdi_mov_prosseguir_critica') && this.violations && this.violations.length > 0) {
        this.$refs.SnackbarCustomize.open('error', 'Erro de validação de regras no preenchimento do formulário');
        return false;
      }

      return true;
    },
    getContractedPlanData(data) {
      return {
        contractId: data.contractId,
        subContractId: data.subContractId,
        eligibilityId: data.eligibilityId,
        planId: data.planId,
        validityDate: data.startValidityDate ? this.formatter.formatDateBRtoString(data.startValidityDate) : null,
        financialGroupId: data.financialGroupId,
        hasAcceptedGracePeriod: data.hasGracePeriod,
      };
    },
    getAddressData(data, typeRequest) {
      const addressData = {
        log: data.log,
        city: data.city,
        country: data.country,
        neighborhood: data.neighborhood,
        number: data.number,
        complement: data.complement,
        ibgeCode: data.ibgeCode,
      };
      const codeFormated = data.code ? data.code.replaceAll('-', '') : null;
      if (typeRequest === 'validate') {
        addressData.logType = data.logType;
        addressData.code = codeFormated;
        addressData.region = data.region;
      } else {
        addressData.type = data.logType;
        addressData.zip = codeFormated;
        addressData.state = data.region;
      }

      return addressData;
    },
    getBankData(data) {
      return {
        code: data.code ? Number(data.code) : null,
        agency: data.agency ? Number(data.agency) : null,
        agencyDigit: data.agencyDigit ? Number(data.agencyDigit) : null,
        account: data.account ? Number(data.account) : null,
        accountDigit: data.accountDigit ? Number(data.accountDigit) : null,
      };
    },
    getHolderData(data) {
      const formattedDate = this.holder.admissionDate ? this.holder.admissionDate.includes('/') : null;
      return {
        admissionDate: formattedDate ? this.formatter.formatDateBRtoString(data.admissionDate) : data.admissionDate,
        cpf: data.document ? this.formatter.removeNonDigit(data.document) : null,
        name: data.name,
      };
    },
    getAdditionalInfo(data, holder) {
      const cleanCellphone = data.cellphone ? data.cellphone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null;
      const cleanTelephone = data.telephone ? data.telephone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null;
      const formatCompanyDocumentNumber = data.companyDocumentNumber ? data.companyDocumentNumber.replace(/\D/g, '') : null;
      
      return {
        motherName: data.motherName,
        cns: data.cns,
        dnv: data.dnv,
        companyName: data.companyName,
        companyDocumentNumber: formatCompanyDocumentNumber,
        pisPasep: data.pisPasep,
        registrationPlate: data.registrationPlate,
        registration: data.registrationPlate,
        nationality: data.nationality,
        stateBirth: data.stateBirth,
        weight: data.weight,
        height: data.height,
        rne: data.rne,
        email: data.email,
        identityDocumentNature: data.identityDocumentNature,
        identityDocumentNumber: data.identityDocumentNumber,
        identityDocumentEmissionDate: data.identityDocumentEmissionDate ? this.formatter.formatDateBRtoString(data.identityDocumentEmissionDate) : null,
        identityDocumentIssuer: data.identityDocumentIssuer,
        identityDocumentState: data.identityDocumentState,
        identityDocumentCountry: data.identityDocumentCountry,
        birthDate: data.birthDate ? this.formatter.formatDateBRtoString(data.birthDate) : null,
        birthday: data.birthDate ? this.formatter.formatDateBRtoString(data.birthDate) : null,
        cellphone: cleanCellphone,
        telephone: cleanTelephone,
        maritalStatusId: data.maritalStatus,
        maritalStatus: data.maritalStatus,
        genderId: data.gender,
        gender: data.gender,
        insuredPosition: data.job,
        job: data.job,
        costCenterDepartment: holder && holder.department ? holder.department : null,
        department: holder && holder.department ? holder.department : null,
        taxAllocationCode: holder && holder.capacityCode ? holder.capacityCode : null,
        capacityCode: holder && holder.capacityCode ? holder.capacityCode : null,
        taxAllocationDescription: holder && holder.capacityDescription ? holder.capacityDescription : null,
        capacityDescription: holder && holder.capacityDescription ? holder.capacityDescription : null,
        employmentRelationshipId: data.employmentRelationship ? Number(data.employmentRelationship) : null,
        employmentRelationship: data.employmentRelationship ? Number(data.employmentRelationship) : null,
        professionalCardNumber: data.workCard,
        workCard: data.workCard,
        registrationComplement: data.registrationComplement ? data.registrationComplement : null,
      };
    },
    scrollTo() {
      this.$refs.top.scrollIntoView({ behavior: 'smooth' });
    },
    getValidateGracePeriod(params) {
      this.contractService.ValidateGracePeriod(params).then((response) => {
        if(response) {
          let hasGracePeriod = false;
          response.data.forEach(element => {
            if(element.hasGracePeriod === true) {
              hasGracePeriod = true;
              this.gracePeriods = response.data;
            }
          });
          if(hasGracePeriod) {
            this.validateGraceModal = true;
          } else {
            this.payload.plan.hasAcceptedGracePeriod = null;
            this.processMovementRecordService();
          }
        }
      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao validar carência');
      });
    },
    closeValidateGraceModal() {
      this.validateGraceModal = false;
    },
    confirmGracePeriod() {
      this.payload.plan.hasAcceptedGracePeriod = true;
      this.closeValidateGraceModal();
      this.processMovementRecordService();
    },
    nonConfirmGracePeriod() {
      this.payload.plan.hasAcceptedGracePeriod = false;
      this.closeValidateGraceModal();
      this.processMovementRecordService();
    },
  },

  async created() {
    this.loaded = true;

    this.userUtils = new UserUtils();
    this.formatter = new Formatters();
    this.rule = new Rule();
    this.genderService = new GenderService();
    this.maritalStatusService = new MaritalStatusService();
    this.kinshipService = new KinshipService();
    this.movementRecordService = new MovementRecordService();
    this.fieldsToValidate = new MovementRecordFormFieldsToValidate();
    this.contractService = new ContractService();
  },
};
</script>
